const terrainSize = 2;
class MovingImage {
    constructor(image) {
        this.image = image;
        this.pos = {
            x: (Math.random() - 0.5) * terrainSize,
            y: (Math.random() - 0.5) * terrainSize,
            z: Math.random() + 1
        };
        this.image.style.zIndex = Math.round(9999 / this.pos.y - 1).toString();
    }
    update() {
        this.image.style.top = `calc(${(this.pos.y + 0.5)} * 100vh - 100px)`;
        this.image.style.left = `calc(${(this.pos.x + 0.5)} * 100vw - 100px)`;
    }
    step() {
        this.pos.y -= 0.001 / this.pos.z;
        if (this.pos.y < terrainSize / -2) {
            this.pos.y = terrainSize / 2;
            this.pos.x = (Math.random() - 0.5) * terrainSize;
            this.pos.z = Math.random() + 1;
            this.image.style.zIndex = Math.round(9999 * (this.pos.y - 1)).toString();
        }
        this.update();
    }
}
const images = Array.from(document.querySelectorAll('.images-effect img'))
    .map(image => new MovingImage(image));
function animate() {
    images.forEach(image => {
        image.step();
    });
    requestAnimationFrame(animate);
}
animate();
